<template>
  <div class="container">
    <span class="theming-custom" style="color: #0b0b0b; font-size: 40px"
      ><strong>Politique de confidentialité</strong></span
    >
    <div>
      <p style="text-align: start">&nbsp;</p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong><u>ARTICLE 1 : PRÉAMBULE</u></strong></span
        >&nbsp;
      </p>
      <p style="text-align: start">&nbsp;</p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >La présente politique de confidentialité a pour but d’informer les
          utilisateurs du site :&nbsp;&nbsp;</span
        >
      </p>
      <ul>
        <li style="text-align: start">
          <span class="theming-custom" style="color: #0b0b0b"
            >Sur la manière dont sont collectées leurs données personnelles.
            Sont considérées comme des données personnelles, toute information
            permettant d’identifier un utilisateur. A ce titre, il peut s’agir :
            de ses noms et prénoms, de son âge, de son adresse postale ou email,
            de sa localisation ou encore de son adresse IP (liste
            non-exhaustive) ;</span
          >
        </li>
        <li style="text-align: start">
          <span class="theming-custom" style="color: #0b0b0b"
            >Sur les droits dont ils disposent concernant ces données ;</span
          >
        </li>
        <li style="text-align: start">
          <span class="theming-custom" style="color: #0b0b0b"
            >Sur la personne responsable du traitement des données à caractère
            personnel collectées et traitées ;</span
          >
        </li>
        <li style="text-align: start">
          <span class="theming-custom" style="color: #0b0b0b"
            >Sur les destinataires de ces données personnelles ;</span
          >
        </li>
        <li style="text-align: start">
          <span class="theming-custom" style="color: #0b0b0b"
            >Sur la politique du site en matière de cookies.</span
          >
        </li>
      </ul>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Cette politique complète les mentions légales et les Conditions
          Générales d’Utilisation consultables par les utilisateurs à l’adresse
          suivante :&nbsp;</span
        >
      </p>
      <p style="text-align: center">
        <a>https://www.mercarue.com/privacy_policy</a>
      </p>
      <p>&nbsp;</p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong
            ><u
              >ARTICLE 2 : PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES
              DONNÉES PERSONNELLES</u
            ></strong
          >&nbsp;</span
        >
      </p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b">&nbsp;&nbsp;</span>
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Conformément à l’article 5 du Règlement européen 2016/679, les
          données à caractère personnel sont :&nbsp; &nbsp;</span
        >
      </p>
      <ul>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Traitées de manière licite, loyale et transparente au regard de la
            personne concernée&nbsp;;</span
          >
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Collectées pour des finalités déterminées (cf. Article 3.1 des
            présentes), explicites et légitimes, et ne pas être traitées
            ultérieurement d'une manière incompatible avec ces finalités ;</span
          >
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Adéquates, pertinentes et limitées à ce qui est nécessaire au
            regard des finalités pour lesquelles elles sont traitées ;</span
          >
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Exactes et, si nécessaire, tenues à jour. Toutes les mesures
            raisonnables doivent être prises pour que les données à caractère
            personnel qui sont inexactes, eu égard aux finalités pour lesquelles
            elles sont traitées, soient effacées ou rectifiées sans tarder
            ;</span
          >
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Conservées sous une forme permettant l'identification des personnes
            concernées pendant une durée n'excédant pas celle nécessaire au
            regard des finalités pour lesquelles elles sont traitées ;</span
          >
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Traitées de façon à garantir une sécurité appropriée des données
            collectées, y compris la protection contre le traitement non
            autorisé ou illicite et contre la perte, la destruction ou les
            dégâts d'origine accidentelle, à l'aide de mesures techniques ou
            organisationnelles appropriées.</span
          >
        </li>
      </ul>
      <p style="text-align: center">&nbsp;</p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Le traitement n'est licite que si, et dans la mesure où, au moins une
          des conditions suivantes est remplie :&nbsp;</span
        >
      </p>
      <ul>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >La personne concernée a consenti au traitement de ses données à
            caractère personnel pour une ou plusieurs finalités spécifiques
            ;</span
          >
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Le traitement est nécessaire à l'exécution d'un contrat auquel la
            personne concernée est partie ou à l'exécution de mesures
            pré-contractuelles prises à la demande de celle-ci ;</span
          >
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Le traitement est nécessaire au respect d'une obligation légale à
            laquelle le responsable du traitement est soumis ;</span
          >
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Le traitement est nécessaire à la sauvegarde des intérêts vitaux de
            la personne concernée ou d'une autre personne physique ;</span
          >
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Le traitement est nécessaire à l'exécution d'une mission d'intérêt
            public ou relevant de l'exercice de l'autorité publique dont est
            investi le responsable du traitement ;</span
          >
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Le traitement est nécessaire aux fins des intérêts légitimes
            poursuivis par le responsable du traitement ou par un tiers, à moins
            que ne prévalent les intérêts ou les libertés et droits fondamentaux
            de la personne concernée qui exigent une protection des données à
            caractère personnel, notamment lorsque la personne concernée est un
            enfant.</span
          >
        </li>
      </ul>
      <p>&nbsp;</p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong
            ><u
              >ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES
              DANS LE CADRE DE LA NAVIGATION SUR LE SITE&nbsp;</u
            ></strong
          ></span
        >
      </p>
      <p>&nbsp;</p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong>Article 3.1 : Données collectées&nbsp;</strong></span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Les données personnelles collectées dans le cadre de notre activité
          sont les suivantes :&nbsp;</span
        >
      </p>
      <p>
        &nbsp; &nbsp; &nbsp;<span class="theming-custom" style="color: #0b0b0b"
          >- Nom</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >&nbsp; &nbsp; &nbsp;- Prénom</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >&nbsp; &nbsp; &nbsp;- Adresse e-mail</span
        >
      </p>
      <p>&nbsp;</p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >La collecte et le traitement de ces données répond à la (aux)
          finalité(s) suivante(s) :&nbsp;</span
        >
      </p>
      <p>
        &nbsp;&nbsp;<span class="theming-custom" style="color: #0b0b0b">
          &nbsp; - Envoi de newsletters</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >. &nbsp; &nbsp;- Envoi d'offre commerciales et promotionnelles</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >. &nbsp; &nbsp;- Informer nos utilisateurs et/ou visiteurs de ce site
          internet de toute évolution de nos services. &nbsp;</span
        ><br />&nbsp;
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong
            >Article 3.2 : Mode de collecte des données&nbsp;</strong
          ></span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Lorsque vous utilisez notre site, sont automatiquement collectées les
          données suivantes :&nbsp;</span
        >
      </p>
      <p>
        &nbsp;&nbsp;<span class="theming-custom" style="color: #0b0b0b">
          &nbsp; - Aucun information nominative n'est collectée automatiquement
          sur ce site.</span
        >&nbsp;
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >D’autres données personnelles sont collectées lorsque vous effectuez
          les opérations suivantes sur la plateforme :&nbsp;</span
        >
      </p>
      <p>
        &nbsp;&nbsp;<span class="theming-custom" style="color: #0b0b0b">
          &nbsp; - Souscription à une newsletter</span
        >
      </p>
      <p>
        &nbsp;&nbsp;<span class="theming-custom" style="color: #0b0b0b">
          &nbsp; - Utilisation de la fonction "contact" quelques soit l'objet de
          votre message. Dans ce cas, les informations collecté ne sont utilisé
          &nbsp; &nbsp; &nbsp; &nbsp;quand dans le but vous répondre à votre
          demande et/ou d'améliorer les services proposés par notre site
          internet.&nbsp;</span
        >
      </p>
      <p>&nbsp;</p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Elles sont conservées par le responsable du traitement dans des
          conditions raisonnables de sécurité, pour une durée de : 1 an</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >La société est susceptible de conserver certaines données à caractère
          personnel au-delà des délais annoncés ci-dessus afin de remplir ses
          obligations légales ou réglementaires.&nbsp;</span
        >
      </p>
      <p>&nbsp;</p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong>Article 3.3 : Hébergement des données&nbsp;</strong></span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Le site www.mercarue.com est hébergé par : OVH</span
        >
      </p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          >EURL au capital social de 190 540 425 euros</span
        >
      </p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          >Immatriculation RCS : &nbsp; INSCRIT (au greffe de LILLEMETROPOLE ,
          le 02/11/1999 )</span
        >
      </p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          >N° TVA : FR 22 424 761 419</span
        >
      </p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          >Siège social : 2, rue Kellermann, 59100 Roubaix.</span
        >
      </p>
      <p style="text-align: center">&nbsp;</p>

      <p style="text-align: center">&nbsp;</p>
      <p style="text-align: center">&nbsp;</p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong style="text-align: center"
            >Article 3.5 : Politique en matière de “cookies”
          </strong>
          <p style="text-align: justify">&nbsp;</p>
          Vous pouvez contrôler l'utilisation des cookies au niveau du
          navigateur. Si vous choisissez de désactiver les cookies, cela peut
          limiter votre utilisation de certaines fonctionnalités ou fonctions de
          ce site internet. Pour plus d'informations sur la façon dont nous
          utilisons les cookies et comment vous pouvez les gérer, veuillez
          consulter notre politique en matière de cookies.
          <p style="text-align: justify">&nbsp;</p></span
        >
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong
            ><u
              >ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA
              PROTECTION DES DONNÉES&nbsp;</u
            ></strong
          >&nbsp;</span
        ><br /><br /><span class="theming-custom" style="color: #0b0b0b"
          >&nbsp;</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong
            >Article 4.1 : Le responsable du traitement des
            données&nbsp;</strong
          ></span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Les données à caractère personnelles sont collectées par Melek
          Jarraya.&nbsp;</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Le responsable du traitement des données à caractère personnel peut
          être contacté de la manière suivante :&nbsp;</span
        >
      </p>
      <p><span class="theming-custom" style="color: #0b0b0b">&nbsp;</span></p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Par mail : contact@mercarue.com ;&nbsp;</span
        >
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong
            >Article 4.2 : Le délégué à la protection des données&nbsp;</strong
          >&nbsp;</span
        ><br /><br /><span class="theming-custom" style="color: #0b0b0b"
          >&nbsp;</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Le délégué à la protection des données de l’entreprise ou du
          responsable est :&nbsp;</span
        ><br /><br /><span class="theming-custom" style="color: #0b0b0b"
          >&nbsp;</span
        >
      </p>

      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Si vous estimez, après nous avoir contactés, que vos droits
          “Informatique et Libertés”, ne sont pas respectés, vous pouvez
          adresser une information à la CNIL.&nbsp;</span
        >
      </p>
      <p>&nbsp;</p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          >ARTICLE 5 : LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE
          TRAITEMENT DES DONNÉES&nbsp;</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Tout utilisateur concerné par le traitement de ses données
          personnelles peut se prévaloir des droits suivants, en application du
          règlement européen 2016/679 et de la Loi Informatique et Liberté (Loi
          78-17 du 6 janvier 1978) :&nbsp; &nbsp;</span
        >
      </p>
      <ul>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Droit d’accès, de rectification et droit à l’effacement des données
            (posés respectivement aux articles 15, 16 et 17 du RGPD)
            ;&nbsp;&nbsp;</span
          ><br /><br />&nbsp;
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Droit à la portabilité des données (article 20 du RGPD)
            ;&nbsp;</span
          ><br /><br />&nbsp;
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Droit à la limitation (article 18 du RGPD) et à l’opposition du
            traitement des données (article 21 du RGPD) ;&nbsp;&nbsp;</span
          ><br /><br />&nbsp;
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Droit de ne pas faire l’objet d’une décision fondée exclusivement
            sur un procédé automatisé ;&nbsp;&nbsp;</span
          ><br /><br />&nbsp;
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Droit de déterminer le sort des données après la mort
            ;&nbsp;&nbsp;</span
          ><br /><br />&nbsp;
        </li>
        <li>
          <span class="theming-custom" style="color: #0b0b0b"
            >Droit de saisir l’autorité de contrôle compétente (article 77 du
            RGPD).</span
          >
        </li>
      </ul>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Pour exercer vos droits, veuillez adresser votre courrier à Melek
          Jarraya ou par mail à contact@mercarue.com</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Afin que le responsable du traitement des données puisse faire droit
          à sa demande, l’utilisateur peut être tenu de lui communiquer
          certaines informations telles que : ses noms et prénoms, son adresse
          e-mail ainsi que son numéro de compte, d’espace personnel ou
          d’abonné.&nbsp;</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Consultez le site cnil.fr pour plus d’informations sur vos
          droits.&nbsp;</span
        ><br /><br /><span class="theming-custom" style="color: #0b0b0b"
          >&nbsp;</span
        >
      </p>
      <p>&nbsp;</p>
      <p style="text-align: center">
        <span class="theming-custom" style="color: #0b0b0b"
          ><strong
            >ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE
            CONFIDENTIALITÉ</strong
          >&nbsp;</span
        ><br /><br /><span class="theming-custom" style="color: #0b0b0b"
          >&nbsp;</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >L’éditeur du site www.mercarue.com se réserve le droit de pouvoir
          modifier la présente Politique à tout moment afin d’assurer aux
          utilisateurs du site sa conformité avec le droit en
          vigueur.&nbsp;</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >Les éventuelles modifications ne sauraient avoir d’incidence sur les
          achats antérieurement effectués sur le site, lesquels restent soumis à
          la Politique en vigueur au moment de l’achat et telle qu’acceptée par
          l’utilisateur lors de la validation de l’achat.&nbsp;</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >L’utilisateur est invité à prendre connaissance de cette Politique à
          chaque fois qu’il utilise nos services, sans qu’il soit nécessaire de
          l’en prévenir formellement.&nbsp;</span
        >
      </p>
      <p>
        <span class="theming-custom" style="color: #0b0b0b"
          >La présente politique, éditée le 01/10/2023, a été mise à jour le
          01/10/2023</span
        >
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import nodesName from "./utils/nodesName";
import nodesFieldName from "./utils/nodesFieldName";

export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
<style>
.card {
  background-color: white;
}
.question {
  background-color: white;
  color: black;
  height: 55px;
  text-align: left;
  font-weight: 400;
}
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: lightblue !important;
  border-color: lightblue !important;
  box-shadow: 0 0 0 0.2rem lightblue;
}
.btn-info:hover {
  color: #fff;
  background-color: lightblue !important;
  border-color: lightblue !important;
}
.btn-info {
  border-color: white;
}
.p {
  color: #19b4bf;
}
.button {
  background-color: #19b4bf !important;
  border-color: #19b4bf !important;
  margin-top: 25px;
  margin-right: 5px;
}
.button:hover {
  background-color: #19b4bf !important;
}
.bg-light {
  background-color: #f2f2f2 !important;
}
</style>
