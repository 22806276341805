<template>
  <div class="container">
    <h3>Besoin d'aide ?</h3>
    <p class="p">Ces réponses pourront vous étre utiles !</p>
    <b-overlay :show="loading" opacity="1">
      <div class="row">
        <div class="col-md-12 bg-light text-right">
          <b-button @click="openCloseAll()" class="button">
            {{ updateTextButton }}
          </b-button>
        </div>
      </div>
      <div class="accordion" style="margin-top: 20px">
        <b-card
          no-body
          class="mb-2 card"
          v-for="list in faq"
          :key="list.id"
          @click="displayResponse(list)"
        >
          <b-card-header header-tag="header" class="p-1">
            <b-button class="question" block variant="info">
              {{ list.question }}

              <i class="float-right fa fa-chevron-down" v-if="!list.show"> </i>
              <i class="float-right fa fa-chevron-up" v-else> </i>
            </b-button>
          </b-card-header>
          <div v-if="list.show" id="my-collapse">
            <b-card-body>
              <b-card-text style="text-align: left">{{
                list.response
              }}</b-card-text>
            </b-card-body>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import nodesName from "./utils/nodesName";
import nodesFieldName from "./utils/nodesFieldName";

export default {
  data() {
    return {
      faq: [],
      question: null,
      response: null,
      loading: false,
    };
  },
  computed: {
    updateTextButton() {
      for (var i = 0; i < this.faq.length; i++) {
        if (this.faq[i].show == false) {
          return "Déplier tout";
        } else {
          return "Replier tout";
        }
      }
    },
  },
  methods: {
    displayResponse(list) {
      list.show = !list.show;
    },
    openCloseAll() {
      for (var i = 0; i < this.faq.length; i++) {
        if (this.faq[i].show == false) {
          this.faq[i].show = true;
        } else {
          this.faq[i].show = false;
        }
      }
    },
    getFaq() {
      this.loading = true;
      firebase
        .firestore()
        .collection(nodesName.FREQUENTLY_ASKED_QUESTIONS)
        .where(nodesFieldName.DELETED, "==", false)
        .orderBy(nodesFieldName.ORDER_FAQ, "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.faq.push({
              question: doc.data().titleFaq,
              response: doc.data().descFaq,
              show: false,
              id: doc.data().uniqueId,
            });
          });
          this.loading = false;
        });
    },
  },
  created() {
    this.getFaq();
  },
};
</script>
<style>
.card {
  background-color: white;
}
.question {
  background-color: white;
  color: black;
  height: 55px;
  text-align: left;
  font-weight: 400;
}
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: lightblue !important;
  border-color: lightblue !important;
  box-shadow: 0 0 0 0.2rem lightblue;
}
.btn-info:hover {
  color: #fff;
  background-color: lightblue !important;
  border-color: lightblue !important;
}
.btn-info {
  border-color: white;
}
.p {
  color: #19b4bf;
}
.button {
  background-color: #19b4bf !important;
  border-color: #19b4bf !important;
  margin-top: 25px;
  margin-right: 5px;
}
.button:hover {
  background-color: #19b4bf !important;
}
.bg-light {
  background-color: #f2f2f2 !important;
}
</style>
