<template>
  <div id="Accueil">
    <!----------components----------------->
    <main-header />
    <main-banner />
    <service />
    <Devis />
    <presentation />
    <Application />
    <recrutement />
    <contact />
    <Partenaires />

    <main-footer />
    <!----------end components ----------------->
  </div>
</template>
<script>
export default {
  name: "Accueil",
  components: {
    /********import components********/
    MainHeader: () => import("@/views/Header"),
    MainBanner: () => import("@/views/MainBanner"),
    Application: () => import("@/views/Application"),
    Service: () => import("@/views/Services"),
    Recrutement: () => import("@/views/Recrutement"),
    Contact: () => import("@/views/Contact"),
    Presentation: () => import("@/views/About"),
    MainFooter: () => import("@/views/Footer"),
    Partenaires: () => import("@/views/Partenaires"),
    Devis: () => import("@/views/DemandeDevis"),
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
