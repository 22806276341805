<template>
  <div class="col-sm-12 text-center">
    <div class="img-responsive">
      <b-img
        src="@/assets/images/404.svg"
        alt="Mercarue website 404 error"
      ></b-img>
      <div class="main-blue-button-hover-error" style="overflow: overlay">
        <a href="/"> Retour Accueil </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  components: {},
};
</script>
<style>
.img-responsive {
  display: inline-block;
  min-width: 350px;
  max-width: 650px;
}
.main-blue-button-hover-error a {
  margin-top: 20px;
  display: inline-block;
  background-color: #19b4bf;
  font-size: 17px;
  font-weight: 400;
  color: #fff !important;
  padding: 12px 20px;
  border-radius: 23px;
  letter-spacing: 0.25px;

  cursor: pointer;
}
.main-blue-button-hover-error a:hover {
  background-color: #ea3490;
  color: #fff !important;
}
</style>
