import Vue from "vue";
import VueRouter from "vue-router";
import Accueil from "@/views/Accueil.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Faq from "@/views/Faq.vue";
import privacy_policy from "@/views/privacy_policy.vue";
import politique_de_confidentialite from "@/views/politique_de_confidentialite.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Accueil",
    component: Accueil,
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/Faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    component: privacy_policy,
  },

  {
    path: "/politique_de_confidentialite",
    name: "politique_de_confidentialite",
    component: politique_de_confidentialite,
  },
 
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
