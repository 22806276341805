import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import firebase from "firebase/app";
import firebaseConfig from "./firebaseConfig";
import smoothscroll from "smoothscroll-polyfill";
// kick off the polyfill!
smoothscroll.polyfill();

firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;

require("@/assets/styles/fontawesome.css");
require("@/assets/styles/templatemo-onix-digital.css");
require("@/assets/styles/animated.css");
require("@/assets/styles/owl.css");

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");
