<template>
  <div class="container">
    <span class="theming-custom" style="color: #0b0b0b; font-size: 40px"
      ><strong>MENTIONS LEGALES</strong></span
    >
    <div
      class="text-root text-e0ce535a-dd99-4d98-b01c-65ffb007e20a"
      style="
        --text-container-color: #777777;
        --text-container-anchor-color: #b56100;
        --font-family: Roboto;
        --font-size: 16px;
        --font-weight: normal;
        --word-wrap: break-word;
        --overflow-wrap: break-word;
        --font-style: normal;
        --text-decoration: none;
        hyphens: none;
      "
    >
      <div>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Conformément aux obligations &nbsp;de l'article 6-III et 19 de la
            Loi numéro 2004-575 du 21 juin 2004 concernant la confiance de
            l'économie numérique, dite L.C.E.N., nous portons à la connaissance
            des utilisateurs du site web : www.mercarue.com Ces données
            ci-dessous :</span
          >
        </p>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            ><strong><u>1. Déclaration légales :</u></strong></span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Le Propriétaire est : Melek Jarraya</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Adresse postale : Sfax Tunisie</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Le créateur du site internet suivant est : Melek Jarraya</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Le responsable du contenu publié sur ce site est : Melek Jarraya
          </span>
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Prendre contact avec le responsable de la publication sur ce site :
            contact@mercarue.com</span
          >
        </p>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Le concepteur du site internet est : Melek Jarraya</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Prendre contact avec le concepteur de ce site internet :
            contact@mercarue.com</span
          >
        </p>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >L'hébergeur du site est : OVH</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >EURL au capital social de 190 540 425 euros</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Immatriculation RCS : INSCRIT (au greffe de LILLEMETROPOLE , le
            02/11/1999 )
          </span>
        </p>

        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >N° TVA : FR 22 424 761 419</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Siège social :2, rue Kellermann, 59100 Roubaix.</span
          >
        </p>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            ><strong><u>2. Présentation et principe:</u></strong></span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Tout internaute utilisant le site internet : mercarue.com est
            considéré comme utilisateur du site.</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Le site internet www.mercarue.com &nbsp;regroupe un ensemble de
            services, en l'état, mis à la disposition des utilisateurs. Il est
            précisé que ces derniers doivent rester courtois et faire preuve de
            bonne foi tant envers les autres utilisateurs qu'envers le
            Concepteur du site mercarue.com. Le site mercarue.com est actualisé
            de façon régulière par Melek Jarraya.</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Melek Jarraya s'efforce de fournir sur le site internet
            www.mercarue.com<i> </i> des informations les plus précises, claires
            et réalistes possibles (sous réserve de modifications apportées
            depuis leur mise en ligne), mais ne saurait garantir l'exactitude,
            la complétude et l'actualité des informations diffusées sur son site
            internet, qu'elles soient de son fait ou non. En conséquence
            l'utilisateur reconnaît utiliser ces informations (à titre
            indicatif, non exhaustives et susceptibles d'évoluer) sous sa
            responsabilité exclusive.</span
          >
        </p>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            ><strong><u>3. Accessibilité Numérique :</u></strong></span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Le site internet www.mercarue.com est par principe accessible aux
            utilisateurs 24/24h, 7/7j, sauf en cas d'interruption, programmée ou
            non, pour les besoins de sa maintenance/mise à jour ou en cas de
            force majeure. En cas d'impossibilité d'accès au service,
            mercarue.com s'engage à faire son maximum pour rétablir l'accès au
            site internet et tentera alors de communiquer préalablement aux
            utilisateurs informations de l'intervention. N'étant soumis qu'à une
            obligation de moyen, www.mercarue.com ne pourrait être tenu
            responsable de tout dommage, quelle qu'en soit sa nature résultant
            d'une indisponibilité du service.</span
          >
        </p>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            ><strong><u>4. Propriété intellectuelle:</u></strong></span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Melek Jarraya est le propriétaire exclusif| de l'intégralité des
            droits de propriété intellectuelle ou détient les droits et
            autorisations d'usage sur l'intégralité des éléments accessibles sur
            le site web, tant sur la structure que sur les articles ,textes,
            images, graphismes, logo, icônes, sons, logiciels... etc.</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Toute reproduction totale ou partielle du site internet
            www.mercarue.com, représentation, modification, adaptation totale ou
            partielle de l'un de ces éléments, quel que soit le moyen ou le
            procédé utilisé, est strictement interdite, sauf autorisation écrite
            préalable de la part de Melek Jarraya, propriétaire du site internet
            (E-mail : contact@mercarue.com), à défaut elle sera considérée comme
            constitutive d'une contrefaçon et passible de poursuite conformément
            aux dispositions des articles L.335-2 et suivants du Code de
            Propriété Intellectuelle.</span
          >
        </p>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            ><strong><u>5. Cookies et Liens Hypertextes :</u></strong></span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Le site internet : www.mercarue.com contient un certain nombre de
            lien hypertextes vers d'autres sites (partenaires,
            informations..etc.) mis en ligne avec l'autorisation de Melek
            Jarraya. Cependant, Melek Jarraya n'a pas la possibilité de vérifier
            l'intégralité du contenu des sites ainsi| visités et décline donc
            toute responsabilité de ce fait|de cette façon quand aux risques
            éventuels de contenus illicites.</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >L'utilisateur est donc informé que durant &nbsp;ses visites sur le
            site internet www.mercarue.com, un ou plusieurs cookies sont
            susceptibles de s'installer automatiquement sur son ordinateur par
            l'intermédiaire de son logiciel de navigation. Un cookie est un
            ensemble de donnée| qui ne permet pas d'identifier l'utilisateur,
            mais qui sauvegarde des informations/données relatives à la
            navigation de celui-ci sur le site interne.&nbsp;</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Le parametrage du logiciel de navigation permet de notifier de la
            présence de cookie et éventuellement, de pouvoir la contester de la
            manière décrite à l'adresse suivante| : www.mercarue.com.
            L'utilisateur à la possibilité toutefois configurer le navigateur de
            son ordinateur pour refuser l'installation des cookies, sachant que
            le refus d'installation d'un cookie peut potentiellement| entraîner
            l'impossibilité d'accéder à certains services. Pour tout blocage des
            cookies, tapez dans un moteur de recherche : blocage des cookies et
            suivez les instructions en fonction de votre navigateur.</span
          >
        </p>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            ><strong
              ><u
                >6. Protection des biens et personnes - Gestion des données
                personnelles :</u
              ></strong
            ></span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >En France, les données personnelles sont notamment protégées par la
            loi n° 78-87 du 6 janvier 1978 ainsi que la loi n° 2004-801 du 6
            août 2004, l'article L. 226-13 du Code pénal et la Directive
            Européenne du 24 octobre 1995.</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Sur le site interne : www.mercarue.com, Melek Jarraya ne collecte
            aucune information personnelles (suivant l'article 4 loi n°78-17 du
            06 janvier 1978) relatives à l'utilisateur que pour le besoin de
            certains services proposés par le site www.mercarue.com.
            L'utilisateur offre ces informations/données en toute connaissance
            de cause, notamment lorsqu'il procède de lui-même à leur saisie. Il
            est alors précisé à l'utilisateur du site interne : www.mercarue.com
            l'obligation ou non de fournir ces informations/données.</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Conformément aux dispositions des articles 38 et suivants de la loi
            78-17 du 6 janvier 1978 relatives à l'informatique, aux fichiers et
            aux libertés, tout utilisateur dispose d'un droit d'accès aux
            informations|, de rectification, de suppression et d'opposition aux
            données/informations personnelles le concernant. Pour l'exercer,
            adressez votre requête à www.mercarue.com par e-mail :
            contact@mercarue.com ou par écrit&nbsp;signé, accompagnée d'une
            copie de pièce d'identité avec signature du titulaire de la pièce,
            en précisant l'adresse à laquelle la réponse devra être
            envoyée.</span
          >
        </p>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Aucune information personnelle de l'utilisateur du site interne :
            www.mercarue.com ne sera publiée à l'insu de l'utilisateur,
            échangée, transférée, vendue sur un support quelconque à des tiers.
            Seule l'hypothèse du rachat du site interne: www.mercarue.com et de
            l'intégralité de ses droits autorise Melek Jarraya à transmettre les
            dites informations personnelles à l'éventuel acquéreur qui serait
            alors à son tour tenu&nbsp;à la même obligation de conservation et
            de modification des données/informations vis à vis de l'utilisateur
            du site interne: www.mercarue.com.</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Le site interne: www.mercarue.com est en conformité avec le
            RGPD.&nbsp;</span
          >
        </p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >consulter notre politique RGPD.</span
          >
        </p>
        <p style="text-align: justify">&nbsp;</p>
        <p style="text-align: justify">
          <span class="theming-custom" style="color: #0b0b0b"
            >Les bases de données sont protégées par les dispositions de la loi
            du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
            relative à la protection juridique des bases de données.</span
          >
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import nodesName from "./utils/nodesName";
import nodesFieldName from "./utils/nodesFieldName";

export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
<style>
.card {
  background-color: white;
}
.question {
  background-color: white;
  color: black;
  height: 55px;
  text-align: left;
  font-weight: 400;
}
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: lightblue !important;
  border-color: lightblue !important;
  box-shadow: 0 0 0 0.2rem lightblue;
}
.btn-info:hover {
  color: #fff;
  background-color: lightblue !important;
  border-color: lightblue !important;
}
.btn-info {
  border-color: white;
}
.p {
  color: #19b4bf;
}
.button {
  background-color: #19b4bf !important;
  border-color: #19b4bf !important;
  margin-top: 25px;
  margin-right: 5px;
}
.button:hover {
  background-color: #19b4bf !important;
}
.bg-light {
  background-color: #f2f2f2 !important;
}
</style>
